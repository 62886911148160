/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Logo from "src/component/Logo";
import Profile from "src/component/images/profile.jpg";

import { PieChart as PieChartIcon } from 'react-feather';

import NavItem from './NavItem';
const sections = [
  {
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/dashboard',
      },
      {
        title: "User Management",
        icon: PieChartIcon,
        href: "/user",
      },
      {
        title: "KYC Management",
        icon: PieChartIcon,
        href: "/kyc",
      },
      {
        title: "Transaction Management",
        icon: PieChartIcon,
        href: "/transaction",
      },
      {
        title: "Event Management",
        icon: PieChartIcon,
        href: "/event",
      },
      {
        title: "Item Purchase Data",
        icon: PieChartIcon,
        href: "/item",
      },
      {
        title: "Entertainment Ticketing",
        icon: PieChartIcon,
        href: "/entertaiment",
      },
      {
        title: "Referral Management",
        icon: PieChartIcon,
        href: "/referral",
      },
      {
        title: "Static Content Management",
        icon: PieChartIcon,
        href: "/static",
      
      },
      {
        title: "FAQ's",
        icon: PieChartIcon,
        href: "/faq",
      
      },
      {
        title: 'Log Out',
        icon: PieChartIcon,
        href: '/',
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: 'calc(100% - 80px)',
    background: theme.palette.primary.main,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: 'pointer',
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              <Box style={{ color: "#ffff", textAlign: "center" }} mb={4}>
               {/* <Link href="/editProfile"> */}
                <img
                src={Profile}
                  width="100"
                  style={{ paddingLeft: 16 }}
                />
                {/* </Link> */}
                <Typography variant="h4">admin name</Typography>
                {/* <Typography variant="h4">{userData.email}</Typography> */}
              </Box>
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
