import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoginLayout from 'src/layouts/LoginLayout';
export const routes = [
  {
    exact: true,
    path: '/',
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/LogIn')),
  },
  {
    exact: true,
    path: '/dashboard',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard')),
  },
  {
    exact: true,
    path: '/user',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement')),
  },
  {
    exact: true,
    path: '/transaction',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/TransactionManagement')),
  },
  {
    exact: true,
    path: '/kyc',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/KYCManagement')),
  },
  {
    exact: true,
    path: '/event',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/EventManagement')),
  },
  {
    exact: true,
    path: '/item',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ItemPurchaseData')),
  },
  {
    exact: true,
    path: '/entertaiment',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/EntertainmentTicketing')),
  },
  {
    exact: true,
    path: '/referral',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ReferralMangement')),
  },
  {
    exact: true,
    path: '/static',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/StaticContent')),
  },
  {
    exact: true,
    path: '/static/view',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/StaticContent/ViewStaticContent/view')
    ),
  },
  {
    exact: true,
    path: '/static/edit',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/StaticContent/EditStaticContent/edit')
    ),
  },

  {
    exact: true,
    path: '/faq',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/FAQ')),
  },
  {
    exact: true,
    path: '/faq/add-screen/add',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/FAQ/AddScreen/add')),
  },
  {
    exact: true,
    path: '/faq/edit-screen/edit',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/FAQ/EditScreen/edit')),
  },
  {
    exact: true,
    path: '/faq/view-screen/view',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/FAQ/ViewScreen/view')),
  },

  {
    exact: true,
    path: '/truck',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/TruckManagement')),
  },
  {
    exact: true,
    path: '/truck/add',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/TruckManagement/AddNewTruck/add')
    ),
  },
  {
    exact: true,
    path: '/truck/order',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/TruckManagement/OrderManagement/order')
    ),
  },

  {
    exact: true,
    path: '/transaction-managent',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/TransactionManagement')),
  },
  {
    exact: true,
    path: '/transaction-managent/view',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/TransactionManagement/View')),
  },

  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
