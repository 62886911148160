import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AuthContext } from 'src/context/Auth';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Badge,
  Button,
  Container,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import { useHistory } from 'react-router-dom';
import Logo from 'src/component/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Logo
          width="180"
          onClick={() => history.push('/dashboard')}
          style={{ cursor: 'pointer', paddingLeft: 16 }}
        />
        <Box flexGrow={1} />
        <IconButton
          style={{ marginRight: 10 }}
        // onClick={() => history.push('/notifications')}
        >
          <Badge badgeContent={auth.NotificationCount} color="secondary">
            <NotificationsIcon style={{ color: "primary" }} />
          </Badge>
        </IconButton>
        <IconButton style={{ marginRight: 10 }}
        >
          <EmailIcon style={{ color: "primary" }} />
        </IconButton>
        <IconButton style={{ marginRight: 10 }}
        >
          <EmojiFlagsIcon style={{ color: "primary" }} />

        </IconButton>
        <Button
          // onClick={handleClickOpen}
          variant="contained"
          color="secondary"
          style={{
            height: 40,
            marginRight: 15,
            borderRadius:5,
          }}
        >
          My Profile
        </Button>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          style={{
            height: 40,
            borderRadius:5,

          }}
        >
          Logout
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to log out?"}
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => history.push('/')} color="primary" autoFocus>
              Log Out
            </Button>
          </DialogActions>
        </Dialog>
        {/* <SwipeableTemporaryDrawer /> */}
      </Container>
    </>
  );
}
